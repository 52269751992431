import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BannerSEO from "../components/BannerSEO";

const LandingSEO = (props) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
            "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://brandinavian.com/seo/#webpage",
        url: "https://brandinavian.com/seo/",
        name: "SEO | Brandinavian",
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        description:
          "Increase the amount of organic visitors on your website, and rank higher in search engines. Get started with Brandinavian today!",
        breadcrumb: {
          "@id": "https://brandinavian.com/seo/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://brandinavian.com/seo"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://brandinavian.com/seo/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Web developer",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/seo",
              url: "https://brandinavian.com/seo",
              name: "Search Engine Optimization (SEO)",
            },
          },
        ],
      },
    ],
  };
  return (
    <Layout altLogo={"black"}>
      <SEO
        title="SEO | Brandinavian"
        description="Increase the amount of organic visitors on your website, and rank higher in search engines. Get started with Brandinavian today!"
        schemaMarkup={schema}
        lang="en-US"
      />
      <BannerSEO />
      <div id="main" className="alt darkmode">
        <section className="pre-article dark">
          <li className="tag">Search Engine Optimization</li>
        </section>
        <section id="one" className="article-section main-service">
          <div className="inner article service-article">
            <header className="major">
              <h2>The black box</h2>
            </header>
            <p>
            The truth when it comes to Search Engine Optimization, is that no
             one can give you a precise, technically founded explanation of how the large
             search engines choose to rank your
             website.<br/><br/> Google themselves, typically <a
               href="https://www.google.com/search/howsearchworks/algorithms/"
               target="_blank"
               rel="noopener"
               rel="noreferrer"
             >
               only allude to how this process works
             </a>
             , and never actually divulge how the different ranking signals are weighed against each other. The question arises: <i>How can we know
             what SEO areas to focus on?</i> The answer is, that we have to cover as many bases as possible. I base my SEO approach on the tactics that produce consistent results for the broader community, and adapt my strategies
             accordingly. In my opinion, this is the ultimate truth of good
             SEO: It is a moving target - and we periodically have to readjust
             our efforts to match the algorithms that potentially change from
             month to month.
            </p>
            <div className="dark-mode-image red">
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>Gaining competitive edge</h2>
            </header>
            <p>
            In {new Date().getFullYear()}, we know that certain things
                   tend to have positive influence on how our websites are treated by
                   the infamous{" "}
                   <a
                     href="https://en.wikipedia.org/wiki/PageRank"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   > <i>PageRank</i>
                   </a>
                   ,{" "}
                   <a
                     href="https://en.wikipedia.org/wiki/RankBrain"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   > <i>RankBrain</i>
                   </a> and <a
                     href="https://en.wikipedia.org/wiki/Google_Hummingbird"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   > <i>Hummingbird</i>
                   </a> algorithms. These cover a broad range of both technical
                   optimizations that we can implement through meta-tags and
                   structured data - however, they also relate to the quality
                   of the content on your website itself. Other factors, like
                   how your website is linked to on websites that already hold
                   'reputability' in the eyes of the different search engines,
                   are also important.{" "}
                   <a
                     href="https://developers.google.com/web/updates/2018/07/search-ads-speed"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   >
                     According to Google
                   </a>
                   , page speed is similarly also a ranking signal - so this
                   should be a key optimization area (a primary one, in fact)
                   in any decent SEO strategy.</p>
                   <div className="dark-mode-image yellow">
              <Img fluid={props.data.imageThree.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
                   <header className="major">
                    <h2>Sizing up the competition</h2>
                  </header>
                   <p>
                   The way that I initially approach SEO, is
                   by identifying technical areas that can be optimized on your website. After this, I then compare
                   your website, to the websites of your competitors - and the average CPC
                   (Cost per click) for popular keywords in your industry. This is to understand, which keywords will be heavily contested by
                   actors, that are running paid advertising campaigns in your
                   vertical. Some verticals have it easier than others. Potential low-bid
                   keywords to be discovered are typically words or phrases
                   that your audience think are vital for what they are trying
                   to find. <b>These days, people tend to ask Google questions</b>. This is a good place to start; Integrating often searched questions into your
                   website, to match the very same keywords that your audience
                   use. This is actually critical, since the{" "}
                   <a
                     href="https://en.wikipedia.org/wiki/Google_Hummingbird"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   >
                     <i>Hummingbird</i>
                   </a> update has been putting a steady emphasis on making natural
                   language rank higher over the years.

                   </p>
            <div className="dark-mode-image yellow">
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>No cure, no pay</h2>
            </header>
            <p>
                   Being successful in
                   your SEO effort is ultimately a matter of being very
                   attentive to who your audience is - <b>writing good content</b>, and
                   getting websites with high reputability to link to yours. At
                   the beginning of a SEO effort with me, I send you a status
                   rapport of how your website is performing before I begin optimizing. I
                   will send you one of these reports <b>every month</b>, and provide
                   you with possible leads for high-impact collaborations that
                   could further the amount of reputable backlinks your website is
                   getting. If I feel that we need to
                   advance our strategy to rank higher (paid advertising), I
                   will tell you - and if I fail to improve upon your initial
                   rank within a six month period, <b>I refund you</b>. Very simple.
            </p>
            <ul className="actions align-center">
              <li>
                <Link to="/contact" className="button">
                  Start ranking today!
                </Link>
              </li>
            </ul>
          </div>
          <div className="inner article about-author">
            <div className="author-image-box">
              <Img
                className="large-author-image"
                fluid={props.data.imageFour.childImageSharp.fluid}
              />
            </div>
            <div className="author-information">
              <header className="major special">
                <h2 className="large-author-name">Hi, I'm Mads</h2>
              </header>
              <p className="author-biography">
                I'm a Full Stack Engineer and Media Science graduate. Through
                Brandinavian, I help awesome people all over the world,
                designing and engineering digital solutions. I build
                best-in-class, scalable web applications, that convert and
                communicate well.
              </p>
              <p className="author-links" />
              <li className="tag">
                <Link to="/contact">Get in touch</Link>
              </li>
              <li className="tag">
                <Link to="/contact">Request Resume</Link>
              </li>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default LandingSEO;

export const seoQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "analytics.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "nocure.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "sizingup.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "creative-author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
